const { WebPubSubClient } = require("@azure/web-pubsub-client");

class Socket {
  url = null;
  connected = false;
  listeners = [];

  constructor(ws_url) {
    if (Socket._instance) {
      return Socket._instance;
    }
    Socket._instance = this;
    this.url = ws_url;
    this.socket = new WebPubSubClient(ws_url);
    this.socket.on("connected", () => {
      console.log("Connected");
      this.connected = true;
    });
    this.socket.on("disconnected", () => {
      console.log("Disconnected");
      this.connected = false;
    });
    this.socket.on("reconnecting", () => {
      console.log("Reconnecting");
      this.connected = false;
    });
    this.socket.on("reconnected", () => {
      console.log("Reconnected");
      this.connected = true;
    });
    this.socket.on("stopped", () => {
      console.log("Stopped");
      this.connected = false;
    });
    this.socket.on("group-message", (message) => {
      let data = JSON.parse(message.message.data);
      // console.log("Group message received: ", data.event, data.content);
      this.emit(data.event, data.content);
    });
    this.socket.on("server-message", (message) => {
      // console.log("Server message received: ", message.message);
      this.emit("server-message", message.message);
    });
    this.start();

    console.log("Socket created");
  }

  async start() {
    await this.socket.start();
    await this.joinGroup("claims");
    this.connected = true;
    console.log("Socket started");
  }

  async joinGroup(group) {
    console.log("Joining group: " + group);
    await this.socket.joinGroup(group);
  }

  async sendToGroup(group, message) {
    if (!this.connected) return;
    // console.log("Sending to group: " + group);
    await this.socket.sendToGroup(group, JSON.stringify(message), "text");
  }

  emit(eventName, data) {
    this.listeners
      .filter(({ name }) => name === eventName)
      .forEach(({ callback }) => setTimeout(callback(data), 0));
  }

  on(name, callback) {
    if (typeof callback === "function" && typeof name === "string") {
      this.listeners.push({ name, callback });
    }
  }

  off(eventName, callback) {
    this.listeners = this.listeners.filter((listener) => {
      return listener.name !== eventName && listener.callback !== callback;
    });
  }

  destroy() {
    this.listeners.length = 0;
  }
}

export default Socket;
