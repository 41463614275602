import axios from "axios";
import authHeader from "./auth-header";
import { markRaw } from "vue";

class Phone {
  started = false;
  token = null;
  device = null;
  ready = null;
  showOutputSelection = false;
  inputDevice = null;
  outputDevice = null;
  ringtoneDevices = [];
  speakerDevices = [];
  onCall = false;
  timer = null;
  recipient = null;
  call = null;
  listeners = [];

  site_id = null;
  claim_reference = null;

  notes = "Call Notes\n\n";
  sid = null;

  constructor() {
    if (Phone._instance) {
      return Phone._instance;
    }
    Phone._instance = this;
  }

  async startUp() {
    this.started = true;
    let data = (
      await axios.get(`https://api.varsanpr.com/api/calls/token`, {
        headers: authHeader(),
      })
    ).data;
    this.token = data.token;
    this.initializeDevice();
    if (!this.timer) {
      this.timer = setInterval(() => {
        if (!this.checkToken()) {
          this.startUp();
        }
      }, 10000);
    }
  }

  checkToken() {
    if (!this.token) {
      return false;
    }
    let tokenParts = this.token.split(".");
    let tokenDecoded = JSON.parse(atob(tokenParts[1]));
    let tokenExp = tokenDecoded.exp;
    let dateNow = new Date();
    let dateNowSeconds = Math.round(dateNow.getTime() / 1000);
    let tokenValid = tokenExp > dateNowSeconds;
    return tokenValid;
  }

  initializeDevice() {
    this.device = markRaw(
      new Twilio.Device(this.token, {
        logLevel: 1,
        codecPreferences: ["opus", "pcmu"],
      })
    );
    this.addDeviceListeners();
    this.getAudioDevices();
    this.device.register();

    this.emit("ready", {});
  }

  addDeviceListeners() {
    this.device.on("registered", () => {
      console.log("Twilio.Device Ready to make and receive calls");
      this.ready = true;
    });
    this.device.on("error", (error) => {
      console.warn("Twilio.Device Error: " + error.message);
    });
    this.device.on("incoming", (connection) => {
      console.log("Incoming connection from " + connection.parameters.From);
    });
    this.device.on(
      "deviceChange",
      this.updateAllAudioDevices.bind(this.device)
    );

    if (this.device.audio.isOutputSelectionSupported) {
      this.showOutputSelection = true;
    }
  }

  async getAudioDevices() {
    await navigator.mediaDevices.getUserMedia({ audio: true });
    this.updateAllAudioDevices();
  }

  updateAllAudioDevices() {
    if (this.device) {
      this.speakerDevices = [];
      this.ringtoneDevices = [];
      this.device.audio.availableOutputDevices.forEach((device, id) => {
        this.speakerDevices.push({
          id: id,
          label: device.label || "Speaker " + (id + 1),
          value: device.deviceId,
          kind: device.kind,
        });
      });
      this.device.audio.availableInputDevices.forEach((device, id) => {
        this.ringtoneDevices.push({
          id: id,
          label: device.label || "Microphone " + (id + 1),
          value: device.deviceId,
          kind: device.kind,
        });
      });
      if (!this.inputDevice) {
        this.inputDevice = this.ringtoneDevices[0].value;
      }
      if (!this.outputDevice) {
        this.outputDevice = this.speakerDevices[0].value;
      }
    }
  }

  updateOutputDevice() {
    this.device.audio.speakerDevices.set(this.outputDevice);
  }

  updateInputDevice() {
    this.device.audio.ringtoneDevices.set(this.inputDevice);
  }

  async makeOutgoingCall(to, site_id = null, claim_reference = null) {
    if (!this.ready) {
      this.startUp();
      setTimeout(() => {
        this.makeOutgoingCall(to, site_id, claim_reference);
      }, 3000);
      return;
    }

    var params = {
      To: to,
    };

    this.recipient = to;
    this.site_id = site_id;
    this.claim_reference = claim_reference;
    this.notes = "Call Notes - " + to + "\n\n";

    if (this.device) {
      console.log("Calling " + this.to + "...");
      const call = await this.device.connect({ params });
      this.call = call;
      console.log("Call SID:", call.parameters.CallSid);
      this.onCall = true;

      call.on("accept", () => {
        console.log("Call accepted");
        this.emit("call-accepted", {});
      });
      call.on("disconnect", () => {
        console.log("Call ended");
        this.onCall = false;
        this.emit("call-disconnected", {});
        this.submitCall({
          sid: call.parameters.CallSid,
          notes: this.notes,
          recipient: this.recipient,
        });
      });
      call.on("cancel", () => {
        console.log("Call cancelled");
        this.onCall = false;
        this.emit("call-disconnected", {});
      });
    } else {
      console.log("Device not ready");
    }
  }

  async sendDigits(digit) {
    console.log(`Sending digits`, digit);
    if (this.call) {
      this.call.sendDigits(digit + "");
    }
  }

  async hangUp() {
    console.log("Hanging up");
    if (this.call) {
      this.call.disconnect();
      this.call = null;
    }
  }

  submitCall(callDetails) {
    console.log("Submitting call details", callDetails);
    axios
      .post(
        `https://api.varsanpr.com/api/calls/call`,
        {
          sid: callDetails.sid,
          notes: callDetails.notes,
          recipient: callDetails.recipient,
          site_id: this.site_id || null,
          claim_reference: this.claim_reference || null,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        console.log("Call details submitted", response);
      })
      .catch((error) => {
        console.error("Error submitting call details", error);
      });
  }

  emit(eventName, data) {
    this.listeners
      .filter(({ name }) => name === eventName)
      .forEach(({ callback }) => setTimeout(callback(data), 0));
  }

  on(name, callback) {
    if (typeof callback === "function" && typeof name === "string") {
      this.listeners.push({ name, callback });
    }
  }

  off(eventName, callback) {
    this.listeners = this.listeners.filter((listener) => {
      return listener.name !== eventName && listener.callback !== callback;
    });
  }

  destroy() {
    this.listeners.length = 0;
  }
}

export default Phone;
